import { FrontendClient } from '@telus/frontend-common';
import {
  ConfigApi,
  DiscoveryApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { ApiRef, createApiRef } from '@backstage/core-plugin-api';

/**
 * {@link @backstage/core-plugin-api#ApiRef}
 */

export const customEventsApiRef: ApiRef<CustomEventsApi> =
  createApiRef<CustomEventsApi>({
    id: 'plugin.custom-events.service',
  });

export interface CustomEventsApi {
  event({ topic, eventPayload }: EventObj): Promise<any>;
}

type deps = {
  discoveryApi: DiscoveryApi;
  configApi: ConfigApi;
  identityApi: IdentityApi;
};

interface EventObj {
  topic: String;
  eventPayload: any;
}

export class CustomEventsClient extends FrontendClient implements CustomEventsApi {
  protected readonly configApi: ConfigApi;

  constructor(deps: deps) {
    super({
      discoveryApi: deps.discoveryApi,
      identityApi: deps.identityApi,
      defaultPlugin: 'events',
    });

    this.configApi = deps.configApi;
  }

  async event({ topic, eventPayload }: EventObj) {
    return this.postRequired(`/event`, {
      topic,
      eventPayload,
    });
  }
}
